import { mapActions, mapGetters, mapMutations } from 'vuex'
import { snakeCase } from 'lodash'
import moment from 'moment'

import validationMixin from '@/mixins/validation'
import mixins from '@/mixins'

import ImageFile from '@/components/image-file/index.vue'
import SlugNameInput from '@/components/slug-name-input/index.vue'

import { eventStatuses, eventTypes } from '@/modules/events/heplers'

export default {
  name: 'events-general',
  mixins: [validationMixin, mixins],
  data () {
    return {
      payload: {
        name: '',
        slug: '',
        countryId: '',
        defaultLocale: '',
        status: '',
        picture: '',
        startDate: '',
        endDate: '',
        type: '',
        city: '',
        pinned: false
      },
      index: null,
      valid: false,
      openDatepicker: false,
      validationErrors: {},
      disabledBtnTag: true,
      tagsSearch: ''
    }
  },
  components: {
    ImageFile,
    SlugNameInput
  },
  computed: {
    ...mapGetters({
      accessCountries: 'profile/accessCountries',
      profile: 'profile/profile',
      currentItem: 'eventsGeneral/currentItem',
      isLoading: 'eventsGeneral/isLoading',
      updateImageLoading: 'eventsGeneral/updateImageLoading'
    }),
    eventTypes,
    eventStatuses,
    listLocales () {
      if (this.accessCountries) {
        const currentCountry = this.accessCountries.find(e => e.country_id === this.payload.countryId)
        if (currentCountry) {
          return currentCountry.locales.data
        } else {
          return []
        }
      }
      return []
    }
  },
  created () {
    if (this.$route.params.hasOwnProperty('id')) {
      this.fetchContent({
        id: this.$route.params.id
      }).then(() => {
        this.setData()
      })
    } else {
      this.setDefaultData()
      this.setData()
    }
  },
  methods: {
    ...mapActions({
      fetchContent: 'eventsGeneral/GENERAL_GET_DATA',
      update: 'eventsGeneral/UPDATE',
      create: 'eventsGeneral/CREATE',
      updateImage: 'eventsGeneral/UPDATE_IMAGE'
    }),
    ...mapMutations({
      setCountryIds: 'profile/SET_COUNTRY_IDS'
    }),
    updateImageLocal (params) {
      params.append('event_id', this.currentItem.id)
      this.updateImage(params).then(() => {
        this.$toasted.success(this.$t('success_updated'))
      })
    },
    setDefaultData () {
      // set default data in fields when created item
      const startedAt = moment().utcOffset(0).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      })
      this.payload.startDate = moment(startedAt).add(2, 'days').format('YYYY-MM-DD HH:mm:ss')

      const endDate = moment().utcOffset(0).set({
        hour: 2,
        minute: 0,
        second: 0,
        millisecond: 0
      })
      this.payload.endDate = moment(endDate).add(2, 'days').format('YYYY-MM-DD HH:mm:ss')
    },
    setData () {
      Object.assign(this.payload, this.currentItem)
    },
    formattedDate (date) {
      if (!date) return ''
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    createRequestPayload () {
      const data = Object.assign(this.payload)
      const formData = {}
      Object.keys(data).forEach(e => {
        formData[snakeCase(e)] = data[e]
      })

      formData.start_date = this.formattedDate(this.payload.startDate)
      formData.end_date = this.formattedDate(this.payload.endDate)
      if (!this.$route.name.includes('.create')) {
        formData._method = 'patch'
      }
      return formData
    },
    submit () {
      this.$refs.form.validate()
      this.$refs.observer.validate()
      if (this.valid) {
        if (!this.$route.name.includes('.create')) {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'))
          })
        } else {
          this.create(this.createRequestPayload()).then(response => {
            this.$toasted.success(this.$t('success_created'))
            this.$router.replace({
              name: 'events.general',
              params: { id: response.id }
            })
          })
        }
      }
    }
  }
}
