export function eventTypes () {
  return [
    {
      name: 'Live',
      slug: 'live',
      id: 1
    },
    {
      name: 'Online',
      slug: 'online',
      id: 2
    }
  ]
}

export function eventTableHead () {
  return [
    {
      text: 'Id',
      align: 'start',
      value: 'id'
    },
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Type',
      value: 'type'
    },
    {
      text: 'Country',
      value: 'country'
    },
    {
      text: 'Status',
      value: 'status'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}

export function eventStatuses () {
  return [
    {
      name: 'Draft',
      slug: 'draft',
      id: 1
    },
    {
      name: 'Test',
      slug: 'test',
      id: 2
    },
    {
      name: 'Deferred',
      slug: 'deferred',
      id: 3
    },
    {
      name: 'Actual',
      slug: 'actual',
      id: 4
    },
    {
      name: 'Deprecated',
      slug: 'deprecated',
      id: 5
    },
    {
      name: 'Wrong',
      slug: 'wrong',
      id: 6
    }
  ]
}
